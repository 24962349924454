<template>
	<div class="app-view">
		<div class="wrapper-app">
			<header class="header">
				<div class="myContainer header-content">
					<figure class="header-content__logo">
						<img :src="require('@/assets/images/logo/Logo_TFC_Negro.png')" alt="logo" class="header-content__img">            
					</figure> 
					<div class="icons-right">
						<svg width="26" height="26" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" @click="skin = isDark ? 'light' : 'dark'">
							<path d="M1.66077 11.3619C2.09296 11.4524 2.54093 11.5 3.00002 11.5C6.58987 11.5 9.50002 8.58984 9.50002 4.99999C9.50002 3.25479 8.81224 1.67024 7.69295 0.502594C11.4697 0.604809 14.5 3.69852 14.5 7.49999C14.5 11.366 11.366 14.5 7.49999 14.5C5.06138 14.5 2.91401 13.253 1.66077 11.3619Z" stroke="currentColor" stroke-linejoin="round"/>
						</svg>  	              
					</div>                
				</div>
			</header>                                      
			<main class="main">
				<div class="myContainer main-content">
					<FindOwners/>            
				</div>
			</main>
			<footer class="foot">
				<div class="footer-content">
					<button @click="_logout">
						<svg width="20" height="20" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M13.5 7.5L10.5 10.75M13.5 7.5L10.5 4.5M13.5 7.5L4 7.5M8 13.5L1.5 13.5L1.5 1.5L8 1.5" stroke="#ffffff"/>
						</svg>
					</button>        
				</div>
			</footer>          
		</div>
	</div>
</template>

<script>

import { mapState,  mapMutations } from 'vuex'
import FindOwners from '@/modules/owners/components/adminUsers/FindOwners'
import Logo from '@/modules/layouts/components/Logo.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from 'vue'
import { addDataUserConnected } from '@/helpers/gtm'
import { auth } from '@/modules/auth/mixins/auth'

export default {
	mixins: [auth],
	components:{
		FindOwners,
		Logo
	},
	mounted(){    
		// addDataUserConnected({page: this.$route.name})
	},
	computed:{
		...mapState('auth',['user','userAdmin']),
		...mapState('auth', ['myContracts']),    
		...mapState('appConfig',['windowWidth']),
	},
	methods:{
		_logout(){
			this.normalLogout()
		},
	},
	setup() {
		const { skin } = useAppConfig()
		const isDark = computed(() => skin.value === 'dark')
		return { skin, isDark }
	}, 
}
</script>

<style lang="scss" scoped>
.app-view{
    height: 100%;
    margin: 0;
    padding: 0;
    // overflow: hidden;
    position: relative;  
}
.wrapper-app{
  display: grid;
  grid-template-rows: 7rem 1fr 3rem; /* Define una fila para el contenido principal y otra para el pie de página */
  grid-template-areas:
    "head"
    "content"
    "footer"; /* Asigna un nombre de área para el contenido principal y para el pie de página */
  height: 100vh; /* Asegúrate de que el contenedor de la cuadrícula ocupe toda la altura de la ventana gráfica */
   max-width: 980px;
  margin: 0 auto;
}
.myContainer{
  margin: 1rem;  
}

.header{
  grid-area: head;  
}

.main {
  grid-area: content;
  padding-bottom: calc(3rem + 2rem); //para que el elemento fixed footer no me oculte parte de este  
  margin: auto auto;
  width: 100%;
}


.foot{
  grid-area: footer;
  position: fixed; /* Fija el pie de página en la parte inferior de la página */
  bottom: 0; /* Asegúrate de que el pie de página esté en la parte inferior de la ventana gráfica */
  left: 0; /* Asegúrate de que el pie de página esté a la izquierda de la ventana gráfica */
  width: 100%; /* Asegúrate de que el pie de página tenga el ancho completo de la ventana gráfica */
}

// Estilos del header

.header-content{
  display: flex;
  justify-content: space-between;
  align-items: center; 
}

.header-content__logo {
  max-width: 200px;
}
.header-content__img{
  width: 100%;
  display: block;
}
.icons-right{
  display: flex;
  justify-content: space-between;
  gap: 2rem;

}

.icons-right svg {
  cursor: pointer;

}

// estilos del footer
.footer-content{
  display: flex;
  flex-direction: row-reverse;
  align-items: center;  
  max-width: 980px;
  margin: 0 auto;
  padding: 1rem;
}

.footer-content button{
  width: 45px;
  height: 45px;
  border: none;
  border-radius: 50%;
  background-color: #C32021;
}
</style>
