<template>
	<div class="container-cards">
		<div class="card ">
			<div class="card-body">
				<b-row class="mb-2">
					<b-col cols="12" class="pb-2 pt-1" md="12">
						<h3 class="float-left">Busqueda de propietarios</h3>
						<div class="text-right float-right" v-if="userAdmin">
							Admin: <span class="badge badge-dark">{{ userAdmin.name }} {{ userAdmin.lastname }}</span>
						</div>
					</b-col>
				</b-row>

				<validation-observer v-slot="{ invalid }">
					<b-form   @submit.prevent="sendHousing" >
						<b-row>
							<b-col md="4" sm="12">
								<ValidationProvider name="Hoteles" rules="required">
									<b-form-group slot-scope="{ valid, errors }" label="Hotel*" >
										<b-form-select
											:state="errors[0] ? false : valid ? true : null"
											v-model="idHotel"
											@input="setIdHotel"
										>
											<option :value="null">Seleccione hotel</option>
											<option
												v-for="hotel in hotels"
												:key="hotel.id"
												:value="hotel.id"
											>
											{{ hotel.name }}
											</option>
										</b-form-select>
										<b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
							</b-col>

							<b-col md="4" sm="12">
								<ValidationProvider  name="Vivienda" :rules="{ integer: true, required: true }">
									<b-form-group slot-scope="{ valid, errors }" label="N° de vivienda*">

										<b-form-input
											type="number"
											placeholder="Buscar por numero de vivienda"
											v-model="numberHouse"
											:state="errors[0] ? false : valid ? true : null"
										>
										</b-form-input>
										<b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
									</b-form-group>
								</ValidationProvider>
							</b-col>

							<b-col md="3" sm="12" class="text-center">
								<b-button
									class="mt-2"
									:disabled="invalid"
									type="submit"
									variant="primary"
								>
									<b-icon icon="search"></b-icon> Buscar
								</b-button>
							</b-col>
						</b-row>
					</b-form>
				</validation-observer>

				<b-row>
					<b-col cols="12 mt-2" md="12">
						<span class="text-center d-block" v-if="isLoadingAuth">
							<b-spinner variant="success" style="width: 1.5rem; height: 1.5rem" />
						</span>
					</b-col>
					<b-col v-if="housing != ''" cols="12 mt-2" md="12">
						<h6 class="text-center">{{ housing.fullname }}</h6>
						<table class="table table-sm table-striped">
							<thead>
								<tr>
									<th scope="col">Correo</th>
									<th scope="col">Nombre</th>
									<th scope="col">telefono</th>
									<th scope="col">Usar</th>
								</tr>
							</thead>
							<tbody>
								<tr
									v-for="(owner, index) in housing.owner"
									:key="index"
									@click="sendInfo(owner)"
								>
									<td scope="row">{{ owner.ownerEmail }}</td>
									<td scope="row">
									{{ owner.ownerName + " " + owner.ownerLastname }}
									</td>
									<td scope="row">{{ owner.ownerPhone }}</td>
									<td scope="row">
										<b-button variant="primary" class="btn-add">
											<feather-icon icon="PlusIcon" size="16" />
										</b-button>
									</td>
								</tr>
							</tbody>
						</table>
					</b-col>
				</b-row>
				<b-alert class="mt-2" variant="danger" :show="!validHousing && !isLoadingAuth && searched">
					<div class="alert-body text-center">
						<span> <strong>¡Sin registros!</strong> No se encontraron propietarios registrados.</span>
					</div>
				</b-alert>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { utils } from "@/modules/owners/mixins/utils"
import { toJson } from "@/helpers/helpers";

export default {
	mixins:[utils],
	async created() {
		if (!this.hotels.length) {
			const { hotels } = await this.getInitialContentCheckout();
			this.setHotels(hotels);
		}
		if(this.userAdmin){
			this.idHotel = this.userAdmin.idHotelSelected || null
			this.numberHouse = this.userAdmin.numberHousingSelected || null
		}
	},
	data() {
		return {
			idHotel:  null,
			numberHouse:  null,
			validHousing: false,
			isLoadingAuth: false,
			searched: false,
		};
	},
	computed: {
		...mapState("owners", ["hotels", "housing"]),
		...mapState("auth", ["user", "userAdmin"]),
		...mapState("market", ["hotels", "languages"]),

		languageUser() {
			const initial = "English";
			return this.user.languageName == "English" ? initial : "Spanish";
		},
	},
	methods: {
		...mapActions('auth', ['readInfoOwner']),
		...mapActions('market', ['getInitialContentCheckout']),
		...mapActions('owners', ['getHousingOwners','pushActionsService']),
		...mapMutations('auth', ['setUser', 'logout', 'setIdHotelAdmin','setIdHousingAdmin','setIdOwnerAdmin']),
		...mapMutations('market', ['setHotels']),
		...mapMutations('owners', ['setDataHousing', 'setDataOwnerLogout']),

		imgAlternativo(event) {
			event.target.src = this.imdDefault;
		},
		formatPhoneNumber(event) {
			let keyCode = event.keyCode ? event.keyCode : event.which;
			//43 es +, 48-57(numeros del 0 al 9, 32 espacio, 45 es - )
			const codesAllowed = [32, 43, 45, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57];
			const respuesta = codesAllowed.includes(keyCode);
			if (!respuesta) event.preventDefault();
		},
		setIdHotel(idHotel){
			if(idHotel){
				this.setIdHotelAdmin({idHotel})
			}
		},
		setIdHousing(idHousing){
			if(!!idHousing){
				this.setIdHousingAdmin({idHousing: parseInt(idHousing)})
			}
		},
		setIdOwner(idOwner){
			if(idOwner){
				this.setIdOwnerAdmin({idOwner})
			}
		},

		async sendHousing() {
			this.isLoadingAuth = true
			const payload = { numberHouse: this.numberHouse, idHotel: this.idHotel, }
			const ownersInfo = await this.getHousingOwners(payload)
			this.setDataHousing(ownersInfo)
			await this.pushData('onClick', 'searchHousing', 'searchHousing', this.$route.name ) // push a back del registro

			this.validHousing = this.housing?.owner?.length > 0 || false

			this.searched = true
			this.isLoadingAuth = false
		},

		async sendInfo(owner) {
			const ownerId = owner.idOwner;
			const { ok, user } = await this.readInfoOwner(ownerId);
			if (ok) {
				const userData = toJson(user)

				userData.languageCode = user.languagecode ? user.languagecode : 'ENG'
				userData.languageName = user.languagename ? user.languagename : 'English'
				this.$i18n.locale = userData && userData.languageName === "English" ? 'en' : 'es'

				delete userData.languagecode
				delete userData.languagename

				this.logout()
				this.setDataOwnerLogout()
				this.setIdOwnerAdmin({idOwner: ownerId})
				this.setIdHousing(this.validHousing ? this.housing?.housingnumber : null)
				this.setUser(userData)

				await this.pushData('onClick', 'selectOwnerHousing', 'selectOwnerHousing', this.$route.name ) // push a back del registro
				this.$router.push({ name: "home" })
			}
		},
	},
};
</script>

<style scoped>
.container-cards {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
	grid-gap: 20px;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
		Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	margin-block-end: 1rem;
}



@media (max-width: 767px) {
	.table-striped,
	.table-striped th {
		font-size: 8px;
	}
	.table-striped td .btn {
		padding: 1px;
	}
}
</style>